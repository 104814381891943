import * as React from 'react';
import { useState } from 'react';
import { Component, ReactNode } from 'react';
import { Route, Switch, Redirect } from 'react-router';
import { Layout } from './components/Layout';
import { ServiceDetailsList } from './components/ServiceDetails';
import { MessagesList } from './components/Messages';
import { IncidentReport } from './components/IncidentReports';
import { Roadmap } from './components/RoadmapTimeline';
import {
    SharePointOnlineReport, ExchangeOnlineReport, ODBOnlineReport,
    SFBOnlineReport, MicrosoftTeamsOnlineReport, Dynamics365Report,
    M365SuiteReport, PlannerReport
} from './components/reports/ServiceReports';
import { Dashboard } from './components/Dashboard';
import { TestComponent } from './components/file';
import { AccessDenied } from './components/AccessDenied';
import { Configure } from './components/Configure';
import './custom.css'
import AuthenticationTemplate from './components/auth/AuthenticationTemplate';
import AuthStart from './components/auth/AuthStart';
import AuthEnd from './components/auth/AuthEnd';


export async function fetchConfig2() {

    const response = await fetch('/api/Config');
    return await response.json();
}

export function fetchConfig() {
    fetch('/api/Config')
        .then((response) => {
            return response.json();
        });
}

/* function App() {
    // current authenticated user
    const [currentUser, setCurrentUser] = useState<AccountInfo>();

    // authentication callback
    const onAuthenticated = async (userAccountInfo: AccountInfo) => {
        setCurrentUser(userAccountInfo);
    };
    console.log(currentUser);

    if (currentUser === undefined) {
        return (
            <div id="Login">
                <AzureAuthenticationButton onAuthenticated={onAuthenticated} />
            </div>
            );
    }
    else {
        return (
            <Switch>
                <Route exact path='/accessDenied' >
                    <AccessDenied />
                </Route>

                <Route exact path='/configure' >
                    <Configure />
                </Route>

                <Route exact path='/'>
                    <Dashboard />
                </Route>

                <MsalAuthenticationTemplate
                    interactionType={InteractionType.Redirect}
                    authenticationRequest={loginRequest}
                >
                    <Layout>
                        
                        <Route exact path='/messages' component={MessagesList} />
                        <Route exact path='/status' component={ServiceDetailsList} />
                        <Route exact path='/events' component={ServiceDetailsList} />
                        <Route exact path='/dashboard' component={Dashboard} />
                        <Route exact path='/roadmap' component={Roadmap} />
                        <Route exact path='/reports/sharepoint' component={SharePointOnlineReport} />
                        <Route exact path='/reports/onedrive' component={ODBOnlineReport} />
                        <Route exact path='/reports/exchange' component={ExchangeOnlineReport} />
                        <Route exact path='/reports/skype' component={SFBOnlineReport} />
                        <Route exact path='/reports/teams' component={MicrosoftTeamsOnlineReport} />
                        <Route exact path='/reports/m365suite' component={M365SuiteReport} />
                        <Route exact path='/reports/dynamics' component={Dynamics365Report} />
                        <Route exact path='/reports/planner' component={PlannerReport} />
                        <Route exact path='/reports' component={IncidentReport} />
                    </Layout>
                </MsalAuthenticationTemplate>
            </Switch>
        );
    }
}

export default App; */

export default class App extends Component {
    static displayName = App.name;

    render() {
        return (           
            <Switch>
                <Route exact path='/accessDenied' >
                    <AccessDenied />
                </Route>
                <Route exact path='/auth-start' >
                    <AuthStart />
                </Route>
                <Route exact path='/auth-end' >
                    <AuthEnd />
                </Route>

                <Route exact path='/configure' >
                    <Configure />
                </Route>

                <AuthenticationTemplate>
                {/* <MsalAuthenticationTemplate
                    interactionType={InteractionType.Redirect}
                    authenticationRequest={loginRequest}
                > */}
                    <Layout>
                        <Route exact path='/'>
                            <Dashboard />
                        </Route>
                        <Route exact path='/messages' component={MessagesList} />
                        <Route exact path='/status' component={ServiceDetailsList} />
                        <Route exact path='/events' component={ServiceDetailsList} />
                        <Route exact path='/dashboard' component={Dashboard} />
                        <Route exact path='/roadmap' component={Roadmap}/>
                        <Route exact path='/reports/sharepoint' component={SharePointOnlineReport} />
                        <Route exact path='/reports/onedrive' component={ODBOnlineReport} />
                        <Route exact path='/reports/exchange' component={ExchangeOnlineReport} />
                        <Route exact path='/reports/skype' component={SFBOnlineReport} />
                        <Route exact path='/reports/teams' component={MicrosoftTeamsOnlineReport} />
                        <Route exact path='/reports/m365suite' component={M365SuiteReport} />
                        <Route exact path='/reports/dynamics' component={Dynamics365Report} />
                        <Route exact path='/reports/planner' component={PlannerReport} />
                        <Route exact path='/reports' component={IncidentReport} />
                    </Layout>
                {/* </MsalAuthenticationTemplate>                */}
                </AuthenticationTemplate>
            </Switch>         
        );
  }
}