import * as React from 'react';
import * as msTeams from '@microsoft/teams-js';
import { MsalContext, useMsal } from "@azure/msal-react";
import { loginRequest } from '../../auth/authConfig';
import { RedirectRequest } from '@azure/msal-browser';

const request : RedirectRequest = {
    scopes: loginRequest.scopes,
    redirectUri: '/auth-end'
}


export default class AuthStart extends React.Component {
    static contextType = MsalContext;

    componentDidMount(){
        if (this.context.accounts.length == 0) {
            this.context.instance.loginRedirect(request);
        }
    }

    public render() {
        return (
            <h1>Please, wait! Signing in a user...</h1>
        );
    }

}
